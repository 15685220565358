<template>
    <div class="w-screen h-screen relative flex justify-center items-end bg">
        <div class="h-2/3 px-6">
            <main class="mx-auto max-w-7xl px-4">
                <div class="text-center">
                    <h1
                        class="text-4xl tracking-tight sm:text-5xl md:text-6xl cursor-pointer"
                        @click="$refs.contact.show()"
                    >
                        <span class="font-extrabold text-color inline">
                            Minimal
                        </span>
                        {{ ' ' }}
                        <span class="font-extrabold text inline">
                            Complexity
                        </span>
                    </h1>
                    <p
                        class="mt-3 max-w-md mx-auto text-base font-semibold text-gray-400 dark:text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
                    >
                        software simplified
                    </p>
                </div>
            </main>
        </div>
    </div>

    <div class="absolute top-3 left-4">
        <object
            class="h-8 w-8 sm:h-10 sm:w-10"
            data="logo.svg"
            type="image/svg+xml"
            alt="logo"
        />
    </div>

    <Contact ref="contact" />
</template>

<style>
.bg {
    background-color: #fff;
}
@media (prefers-color-scheme: dark) {
    .bg {
        background-color: #111;
    }
}

.text {
    color: #333;
}
@media (prefers-color-scheme: dark) {
    .text {
        color: #e0e0e0;
    }
}

.text-color {
    /* color: rgb(235, 30, 30); */
    color: rgb(0, 125, 255);
}
@media (prefers-color-scheme: dark) {
    .text-color {
        /* color: rgb(205, 0, 0); */
        color: rgb(0, 90, 255);
    }
}
</style>

<script>
import Contact from './Contact.vue'

export default {
    components: {
        Contact,
    },
}
</script>
