<template>
    <Home />
</template>

<script>
import Home from './components/Home.vue'

export default {
    name: 'App',
    title: 'Minimal Complexity Inc.',
    components: {
        Home,
    },
}
</script>
