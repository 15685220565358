<template>
    <TransitionRoot as="template" :show="open">
        <Dialog
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="open = false"
        >
            <div
                class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay
                        class="fixed inset-0 backdrop-filter backdrop-blur-3xl"
                    />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 sm:scale-95"
                    enter-to="opacity-100 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 sm:scale-100"
                    leave-to="opacity-0 sm:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-gradient-to-br from-gray-50 to-gray-200 border-gray-200 dark:from-gray-200 dark:to-gray-400 border dark:border-gray-400 dark:bg-gray-200 rounded-lg px-6 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6"
                    >
                        <div class="mt-3 sm:mt-5">
                            <DialogTitle
                                as="h2"
                                class="text-xl leading-6 font-extrabold text-gray-900"
                            >
                                Interested in working with us?
                            </DialogTitle>
                        </div>

                        <div class="px-1 py-8 overflow-hidden lg:py-12">
                            <div class="relative max-w-xl mx-auto">
                                <div class="">
                                    <form
                                        action="javascript:false"
                                        class="grid grid-cols-1 gap-y-6 sm:gap-x-8"
                                    >
                                        <div class="col-span-2">
                                            <label
                                                for="name"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Name
                                            </label>
                                            <div class="mt-1">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    autocomplete="name"
                                                    v-bind:disabled="!open"
                                                    class="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-span-2">
                                            <label
                                                for="email"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Email
                                            </label>
                                            <div class="mt-1">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    autocomplete="email"
                                                    v-bind:disabled="!open"
                                                    class="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-span-2">
                                            <label
                                                for="message"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Message
                                            </label>
                                            <div class="mt-1">
                                                <textarea
                                                    id="message"
                                                    name="message"
                                                    rows="4"
                                                    v-bind:disabled="!open"
                                                    class="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="sm:col-span-2 flex justify-center"
                                        >
                                            <button
                                                class="w-full sm:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                @click="open = false"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
// import { ref } from 'vue'
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'

export default {
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {
        show() {
            this.open = true
        },
        hide() {
            this.open = false
        },
    },
}
</script>
